import React from "react";
import { Row, Col } from "react-bootstrap";
import homepageVideo from "../assets/videos/homepage_video.mp4";
import homepageImgMobile from "../assets/images/Mobile home page_new.png";
import "./sass/Homepage.scss";

export default function Section() {
    function getText() {
        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="position-fixed title text-center">
                    <span className="part-1">
                        When presentation meets motion,
                    </span>
                    <br />
                    <span className="part-2">THERE'S PRESMO.</span>
                </div>
            </div>
        );
    }
    return (
        <div className="homepage h-100" id="home">
            <Row className="h-100 no-gutters">
                <Col className="h-100 d-block d-sm-none">
                    <img
                        src={homepageImgMobile}
                        className="position-fixed m-auto w-100 h-100"
                        alt="Homepage background graphic"
                    />
                    {getText()}
                </Col>
                <Col className="h-100 d-none d-sm-block">
                    <video
                        src={homepageVideo}
                        autoPlay
                        loop
                        muted
                        className="position-fixed m-auto w-100 h-100"
                    />

                    {getText()}
                </Col>
            </Row>
        </div>
    );
}
