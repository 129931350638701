import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./sass/StingVideo.scss";

export default function Section() {
    return (
        <Container fluid className="sting-video position-relative">
            <Row className="sting-video__row sting-video__top-row">
                <Col></Col>
            </Row>
            <Row className="sting-video__row bg-white">
                <Col></Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="sting-video__video  position-absolute">
                    <iframe
                        className="shadow"
                        title=""
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/Rc9-i-Aw5n4"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </Col>
            </Row>
        </Container>
    );
}
