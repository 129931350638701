import React from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "./sass/ContactForm.scss";
import hiIcon from "../assets/images/Contact-11.svg";
//import phoneIcon from "../assets/images/Icons-phone.svg";
import mailIcon from "../assets/images/Icons-mail.svg";

export default class ContactForm extends React.Component {
    constructor() {
        super();
        this.state = {
            result: null,
            form: {
                name: "",
                email: "",
                desc: "",
            },
        };
        this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        };
        axios
            .post("https://api.presmo.co.uk/submit", this.state.form, config)
            .then((response) => {
                this.setState({ result: response });
            });
        this.setState({ result: true });
        this.setState({
            form: {
                name: "",
                email: "",
                desc: "",
            },
        });
    }
    handleNameInputChange(e) {
        this.setState({
            form: {
                ...this.state.form,
                name: e.target.value,
            },
        });
    }
    handleEmailInputChange(e) {
        this.setState({
            form: {
                ...this.state.form,
                email: e.target.value,
            },
        });
    }
    handleMessageInputChange(e) {
        this.setState({
            form: {
                ...this.state.form,
                desc: e.target.value,
            },
        });
    }

    getButton() {
        return this.state.result == null ? (
            <Button
                type="submit"
                variant="light"
                size="lg"
                bsPrefix="presmobtn"
                className="contact-form__button"
            >
                Submit
            </Button>
        ) : (
            <Alert variant="success">Thank you for your message!</Alert>
        );
    }

    render() {
        return (
            <Container
                id="contact"
                fluid
                className="contact-form text-white py-6"
            >
                <Row>
                    <Col
                        xs={12}
                        sm={{ span: true, offset: 5 }}
                        className="mb-5 mb-md-0"
                    >
                        <h1 className="h1 mb-5 text-center text-sm-start">
                            Contact
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={5} className="mb-5 mb-md-0">
                        <img
                            className="hi-there"
                            src={hiIcon}
                            alt="'Hi there' inside a speech bubble"
                        />
                    </Col>
                    <Col xs={12} sm={6} className="d-flex align-items-center">
                        <Row className="w-100">
                            <Col>
                                <Form onSubmit={(e) => this.handleSubmit(e)}>
                                    <Row>
                                        <Form.Group as={Col} className="my-3">
                                            <Form.Control
                                                placeholder="Name"
                                                size="lg"
                                                value={this.state.form.name}
                                                onChange={(e) =>
                                                    this.handleNameInputChange(
                                                        e
                                                    )
                                                }
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="my-3">
                                            <Form.Control
                                                placeholder="Email"
                                                type="email"
                                                size="lg"
                                                value={this.state.form.email}
                                                onChange={(e) =>
                                                    this.handleEmailInputChange(
                                                        e
                                                    )
                                                }
                                                required
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group as={Col} className="my-3">
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Message"
                                            size="lg"
                                            value={this.state.form.desc}
                                            onChange={(e) =>
                                                this.handleMessageInputChange(e)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                    <Col className="d-flex">
                                        {this.getButton()}
                                    </Col>
                                </Form>
                                <Row className="mt-5">
                                    {/*<Col xl={5}>
                                        <img
                                            className="icon"
                                            src={phoneIcon}
                                            alt="phone icon"
                                        />
                                        12345678910
                                    </Col>*/}
                                    <Col>
                                        <img
                                            className="icon"
                                            src={mailIcon}
                                            alt="mail icon"
                                        />{" "}
                                        info@presmo.co.uk
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}
