import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import "./sass/Header.scss";

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.linkHrefs = [];
    }

    getHeaderLinks() {
        const headerLinks = this.props.headerLinks;
        if (
            headerLinks === null ||
            headerLinks === undefined ||
            headerLinks.length === 0
        ) {
            return;
        }
        return headerLinks.map((obj, i) => {
            return (
                <Nav.Link
                    key={i}
                    href={obj.href}
                    className="px-3 d-flex justify-content-end align-items-center d-flex justify-content-sm-center"
                    bsPrefix="header__link"
                >
                    {obj.text}
                </Nav.Link>
            );
        });
    }

    render() {
        return (
            <Navbar expand="lg" role="navigation" className="header px-3">
                <Navbar.Brand href="#home" className="mr-auto">
                    <div className="header__logo"></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-end"
                >
                    <Nav className="align-items-end">
                        {this.getHeaderLinks()}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

Header.propTypes = {
    autoHide: PropTypes.bool,
    doPassFirstPageAction: PropTypes.bool,
    scrollY: PropTypes.number,
    pageHeight: PropTypes.number,
    headerLinks: PropTypes.array,
};
