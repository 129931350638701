import React from "react";
import icon from "../assets/images/Icons_Back to top.svg";
import "./sass/BackToTop.scss";
const PASSED_FIRST_PAGE_CLASS = "back-to-top--show";
export default class BackToTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passedFirstPage: false,
        };

        this.prevScrollPos = 0;

        this.handlePassedFirstPage = this.handlePassedFirstPage.bind(this);
    }

    setStatePassedFirstPage(val) {
        this.setState({
            passedFirstPage: val,
        });
    }

    getPassedFirstPageClass() {
        if (this.state.passedFirstPage) {
            return PASSED_FIRST_PAGE_CLASS;
        }
        return "";
    }

    setStatePrevScrollPos(val) {
        this.setState({
            prevScrollPos: val,
        });
    }

    componentDidUpdate() {
        this.handleScroll();
    }

    componentDidMount() {
        this.handleScroll();
    }

    handleScroll() {
        this.handlePassedFirstPage();
    }

    handlePassedFirstPage() {
        this.doPassFirstPageAction();
        this.doNotPassFirstPageAction();
    }

    doPassFirstPageAction() {
        if (
            this.isScrollPositionPassedFirstPage() === true &&
            this.state.passedFirstPage === false
        ) {
            this.setStatePassedFirstPage(true);
        }
    }

    doNotPassFirstPageAction() {
        if (
            this.isScrollPositionPassedFirstPage() === false &&
            this.state.passedFirstPage === true
        ) {
            this.setStatePassedFirstPage(false);
        }
    }

    isScrollPositionPassedFirstPage() {
        return this.props.scrollY > this.props.pageHeight;
    }

    render() {
        return (
            <a
                href="#home"
                className={`back-to-top ${this.getPassedFirstPageClass()}`}
            >
                <img src={icon} alt="Back to top" />
            </a>
        );
    }
}
