import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./sass/TealBanner.scss";

export default function Section() {
    return (
        <Container fluid className="teal-banner py-6">
            <Row className="justify-content-sm-center mb-3">
                <Col sm={12} md={12} lg={9} xl={6}>
                    <div>
                        <p className="teal-banner__white text-white">
                            We are a creative <br /> presentation design studio
                        </p>
                        <p className="teal-banner__dark">
                            dedicated to reframing your story <br /> in the most
                            compelling and innovative ways.
                        </p>
                    </div>
                    <Button
                        className="teal-banner__button "
                        size="lg"
                        href="#contact"
                        bsPrefix="presmobtn"
                    >
                        Say Hi
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
