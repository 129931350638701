import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./sass/Portfolio.scss";

import portfolioThumb1 from "../assets/images/portfolio/1_cover.png";
import portfolioThumb2 from "../assets/images/portfolio/2_cover.png";
import portfolioThumb3 from "../assets/images/portfolio/3_cover.png";
import portfolioThumb4 from "../assets/images/portfolio/4_cover.png";
import portfolioThumb5 from "../assets/images/portfolio/5_cover.png";

export default function Section() {
    return (
        <Container fluid className="portfolio py-6" id="portfolio">
            <h1 className="h1 text-center mb-5">Portfolio</h1>
            <div className="portfolio__container d-flex flex-wrap justify-content-center">
                {[
                    {
                        thumb: portfolioThumb1,
                        code: "eS6zXNg7hH4",
                    },
                    {
                        thumb: portfolioThumb5,
                        code: "HCs-JZXFk_w",
                    },
                    {
                        thumb: portfolioThumb2,
                        code: "0_LxVBVg7YY",
                    },
                    {
                        thumb: portfolioThumb3,
                        code: "JLeDh1UmeiA",
                    },
                    {
                        thumb: portfolioThumb4,
                        code: "771notqqf_o",
                    },
                ].map((el, i) => (
                    <PortfolioSquare
                        key={i}
                        thumb={el.thumb}
                        code={el.code}
                        index={i + 1}
                    />
                ))}
            </div>
        </Container>
    );
}

const PortfolioSquare = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const title = "Portfolio Item " + props.index;
    return (
        <>
            <div className="portfolio__square" onClick={handleShow}>
                <img src={props.thumb} width="100%" alt={title} />
            </div>
            <Modal show={show} onHide={handleClose} size="xl" centered>
                <Modal.Body>
                    <iframe
                        title={title}
                        width="100%"
                        height="640"
                        src={`https://www.youtube.com/embed/${props.code}`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </Modal.Body>
            </Modal>
        </>
    );
};
