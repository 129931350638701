import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import beyondImpossibleVideo from "../assets/videos/Presmo website_slogan banner.mp4";
import beyondImpossibleImg from "../assets/images/Mobile slogan.png";
import "./sass/BeyondImpossible.scss";

export default function Section() {
    return (
        <Container fluid className="beyond-impossible">
            <Row>
                <Col>
                    <img
                        className="w-100 d-block d-sm-none"
                        src={beyondImpossibleImg}
                    />
                    <video
                        className="w-100 d-none d-sm-block"
                        src={beyondImpossibleVideo}
                        autoPlay
                        loop
                        muted
                    />
                </Col>
            </Row>
        </Container>
    );
}
