import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./sass/Footer.scss";
//import twitIcon from "../assets/images/Icons-twitter.svg";
//import linkedInIcon from "../assets/images/Icons-linkedin.svg";

export default function About() {
    return (
        <Container fluid className="footer py-4 px-5">
            <Row>
                <Col sm={12} className="d-flex align-items-center text-center">
                    <p>&copy; 2022 Presmo. All rights reserved.</p>
                </Col>
                {/*<Col
                    sm={{ offset: 4, span: 2 }}
                    className="d-flex justify-content-end align-items-center"
                >
                    {[
                        {
                            icon: twitIcon,
                            alt: "Twitter",
                            href: "https://twitter.com/wearePresmo",
                        },
                        { icon: linkedInIcon, alt: "LinkedIn", href: "#" },
                    ].map((el, i) => (
                        <a href={el.href} target="_blank">
                            <img
                                className="footer__icon mx-4"
                                src={el.icon}
                                alt={el.alt}
                            />
                        </a>
                    ))}
                </Col>*/}
            </Row>
        </Container>
    );
}
