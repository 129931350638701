import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";

import serviceImg1 from "../assets/images/Presmo web_service long illustration 1.svg";
import serviceImg2 from "../assets/images/Presmo web_service long illustration 2.svg";
import serviceImg3 from "../assets/images/Presmo web_service long illustration 3.svg";
import serviceImg4 from "../assets/images/Presmo web_service long illustration 4.svg";
import "./sass/Services.scss";

export default function Section() {
    const getServicesMenuPage = () => {
        return (
            <Container fluid className="service-menu py-6" id="services">
                <div className="service-menu__container">
                    <h1 className="h1 text-center">Our Services</h1>
                    <Row className="justify-content-center">
                        <Col xl={10} lg={11} md={11} sm={10}>
                            <Row>
                                {[
                                    {
                                        href: "#presentation-design",
                                        imgSrc: serviceImg1,
                                        title: "Presentation Design",
                                        highlightText:
                                            "All-round <br /> amazing",
                                    },
                                    {
                                        href: "#dynamic-animation",
                                        imgSrc: serviceImg2,
                                        title: "Dynamic Animation",
                                        highlightText:
                                            "Empower <br /> your story",
                                    },
                                    {
                                        href: "#infographics",
                                        imgSrc: serviceImg3,
                                        title: "Infographics",
                                        highlightText:
                                            "Look! <br /> Don't read",
                                    },
                                    {
                                        href: "#motion-graphics-3d",
                                        imgSrc: serviceImg4,
                                        title: "Motion Graphics",
                                        highlightText: 'Meet <br /> the "WOW"',
                                    },
                                ].map((el, i) => (
                                    <ServiceMenuBox
                                        key={i}
                                        href={el.href}
                                        imgSrc={el.imgSrc}
                                        title={el.title}
                                        highlightText={el.highlightText}
                                    />
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    };

    const getServicesPage = () => {
        return (
            <div className="services pb-5">
                <Container fluid>
                    {[
                        {
                            img: serviceImg1,
                            id: "presentation-design",
                            heading: "Presentation Design",
                            copy: `
                            <p class="font-weight-bold">We offer three levels of services:</p>
                            <p><b>Revamp</b><br /> Is your content presented in an
                            eye-pleasing way? Do you need a hand to make your
                            slides look tidier? We take your slides to the next
                            level with a slick and professional look under your
                            brand guidelines.</p>
                            <p><b>Creation</b><br /> Do you struggle to find the best
                            way to outline your story? By providing whatever you
                             have in mind, we apply the magic to turn your
                             thoughts into beautifully designed pieces which is
                              what your messages deserve.</p>
                            <p><b>Premium</b><br /> If you would like your slides to look
                            extremely high-end and creative, this is the route
                            for you. Impress your audiences with our incredible
                            Premium service that enables the potentials of your
                            slides even far beyond your imagination and yet you
                            can still edit them however you’d like. Make your
                            story stand out.</p>
                            `,
                        },

                        {
                            img: serviceImg2,
                            id: "dynamic-animation",
                            heading: "Dynamic Animation",
                            copy: `<p>How nice would it be if you can explain
                            your messages in a quick, clear and vivid way? How
                             cool would it be if your slides looked far beyond
                             what you imagined is possible? You can blow your
                             audiences away by utilising our highly creative
                             dynamic animations. You will be amazed by the
                             smooth edge-cutting animation, eye-catching visuals
                              and exciting storytelling narration we are able to
                              provide which looks nothing like slides.
</p>`,
                        },
                        {
                            img: serviceImg3,
                            id: "infographics",
                            heading: "Infographics",
                            copy: `<p>With our professionally designed
                            presentations, your data is more than just numbers
                            in a table, and your information is more than just
                            text on a screen. We can transform your words into
                            stunning graphics while retaining the accuracy. We
                            know exactly how to maximise the potential of your
                            data, appealing to your audiences in creative and
                            memorable ways, ensuring your message is delivered
                            with precision, sure to make a lasting impression.
                            </p>`,
                        },
                        {
                            img: serviceImg4,
                            id: "motion-graphics-3d",
                            heading: "Motion Graphics",
                            copy: `<p>Undoubtedly, slides have their limitations. You’d want to, sometimes, attract the full attention of your audience. You want something that REALLY shouts out. With this service, we can craft tailored mind-blowing videos based on your needs with sophisticated stylish 2D or 3D animation to bring your story to life and make it stand out.</p> `,
                        },
                    ].map((el, i) => (
                        <ServicesRow
                            key={i}
                            img={el.img}
                            id={el.id}
                            heading={el.heading}
                            copy={el.copy}
                        />
                    ))}
                </Container>
            </div>
        );
    };

    return (
        <>
            {getServicesMenuPage()}
            {getServicesPage()}
        </>
    );
}

const ServicesRow = (props) => {
    return (
        <Row id={props.id} className="justify-content-center py-5">
            <Col sm={12} md={4} className="p-5 d-none d-md-block">
                <ReactSVG src={props.img} className="svg" />
            </Col>
            <Col
                className="d-flex justify-content-center flex-column"
                sm={12}
                md={5}
            >
                <h2 className="h2 mb-5">{props.heading}</h2>
                <div className="d-flex justify-content-center">
                    <ReactSVG
                        src={props.img}
                        className="d-block d-md-none mb-5 w-75"
                    />
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.copy }} />
            </Col>
        </Row>
    );
};

ServicesRow.propTypes = {
    id: PropTypes.string,
    img: PropTypes.string,
    heading: PropTypes.string,
    copy: PropTypes.string,
};

const ServiceMenuBox = (props) => {
    return (
        <Col
            className="px-4 service-menu-box d-flex flex-column align-items-center justify-content-center text-center position-relative mb-5 mb-lg-0"
            sm={12}
            md={6}
            lg={3}
            xl={3}
        >
            <div className="service-menu-box__box shadow">
                <img src={props.imgSrc} alt={props.title} />
                <div className="service-menu-box__overlay"></div>
                <div
                    className="service-menu-box__overlay-text"
                    dangerouslySetInnerHTML={{
                        __html: props.highlightText,
                    }}
                />
            </div>
            <a
                href={props.href}
                className="service-menu-box__text pt-4 stretched-link text-decoration-none"
            >
                {props.title}
            </a>
        </Col>
    );
};

ServiceMenuBox.propTypes = {
    imgSrc: PropTypes.string,
    title: PropTypes.string,
    highlightText: PropTypes.string,
    href: PropTypes.string,
};
