import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./sass/About.scss";
import aboutImage from "../assets/images/about.svg";

export default function About() {
    return (
        <Container fluid className="about py-6" id="about">
            <Row className="justify-content-center mb-5">
                <Col sm={12}>
                    <h1 className="h1 text-center">About</h1>
                </Col>
            </Row>
            <Row>
                <Col
                    md={5}
                    lg={{ offset: 1, span: 4 }}
                    xl={{ offset: 2, span: 3 }}
                    className="about__para1 mt-xl-4 mt-lg-5  d-flex align-items-center"
                >
                    <p className="mb-5 mb-sm-0">
                        We are a UK-based presentation design studio with{" "}
                        <span className="emph">20+ years</span> of experience,
                        specialising in everything from Creative Presentations
                        to dynamic 3D animations.
                    </p>
                </Col>

                <Col
                    xl={3}
                    lg={4}
                    md={{ offset: 0, span: 6 }}
                    sm={{ offset: 3, span: 6 }}
                    xs={{ offset: 2, span: 8 }}
                >
                    <img className="about__img" src={aboutImage} alt="" />
                </Col>
            </Row>
            <Row>
                <Col
                    md={{ offset: 6, span: 5 }}
                    lg={{ offset: 7, span: 4 }}
                    xl={{ offset: 7, span: 3 }}
                    className="about__para2 mt-sm-4 mt-xl-n4 mt-lg-n5"
                >
                    <p className="mt-5 mt-sm-0">
                        Using our visual storytelling skills, we design{" "}
                        <span className="emph">
                            clear, compelling, and persuasive
                        </span>{" "}
                        presentations, helping some of the biggest brands lead
                        the way, by setting a new pace.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
