import React from "react";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import TealBanner from "./components/TealBanner";
import StingVideo from "./components/StingVideo";
import Services from "./components/Services";
import BeyondImpossible from "./components/BeyondImpossible";
import Portfolio from "./components/Portfolio";
import ContactForm from "./components/ContactForm";
import About from "./components/About";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";
import _ from "lodash";
import "./sass/App.scss";

export default class App extends React.Component {
    constructor() {
        super();
        this.state = {
            scrollY: 0,
        };

        this.setScrollState = this.setScrollState.bind(this);
    }
    componentDidMount() {
        this.handleScroll();
    }

    handleScroll() {
        window.addEventListener("scroll", _.debounce(this.setScrollState, 17));
    }

    setScrollState() {
        this.setState({
            scrollY: window.scrollY,
        });
    }

    render() {
        return (
            <>
                <Header
                    headerLinks={[
                        { href: "#home", text: "Home" },
                        { href: "#services", text: "Services" },
                        { href: "#portfolio", text: "Portfolio" },
                        { href: "#about", text: "About" },
                        { href: "#contact", text: "Contact" },
                    ]}
                />
                <Homepage />
                <TealBanner />
                <StingVideo />
                <Services />
                <BeyondImpossible />
                <Portfolio />
                <About />
                <ContactForm />
                <Footer />
                <BackToTop scrollY={this.state.scrollY} pageHeight={100} />
            </>
        );
    }
}
